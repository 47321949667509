<template>
  <div class="search">
    <h1>This is a search page</h1>
  </div>
  <font-awesome-icon icon="search" /><input v-model="search">
  <button class="btn-secondary" @click="getResults">Rechercher</button>
  <button class="btn-secondary" @click="getLucky"> J'ai de la chance</button>

  <div v-if="error">Veuillez saisir un terme de recherche...</div>

  <div v-if="nbresults === 0 ">Aucun résultat pour le terme <span class="redtext">{{ term }}</span></div>
  <div v-else-if="nbresults > 0">{{ nbresults }} résultat(s) pour le terme <span class="redtext">{{ term }}</span></div>

  <div class="search-results">
    <router-link v-for="r in results" :key="r.id" :to="{ name: 'Meal', params: { id: r.idMeal }}">
      <div class="search-result">
        <div class="img-holder">
          <img :src="r.strMealThumb">
        </div>
        <div class="result-term">
          <h4>{{ r.strMeal }}</h4>
          <p>Catégorie : <span class="redtext">{{ r.strCategory }}</span><br>
          Zone géo : <span class="redtext">{{ r.strArea }}</span></p>
        </div>
      </div>
    </router-link>
  </div>

</template>

<script>
export default {
  name: 'Search',
  data () {
    return {
      search: '',
      results: [],
      error: false,
      nbresults: null,
      term: ''
    }
  },
  methods: {
    getResults () {
      if (this.search !== '') {
        this.reset()
        this.term = this.search
        fetch('https://www.themealdb.com/api/json/v1/1/search.php?s=' + this.search)
          .then(response => response.json())
          .then(response => {
            if (response.meals === null) this.nbresults = 0
            else {
              this.nbresults = response.meals.length
              this.results = response.meals
            }
          })
      } else this.error = true
    },
    getLucky () {
      this.reset()
      this.search = ''
      fetch('https://www.themealdb.com/api/json/v1/1/random.php')
        .then(response => response.json())
        .then(response => {
          this.results = response.meals
        })
    },
    reset () {
      this.error = false
      this.term = ''
      this.nbresults = null
      this.results = []
    }
  }
}
</script>

<style scoped>
  input {
    padding: 9px;
    margin: 12px;
    width: 300px;
    border: 0;
    border-bottom: 1px solid black;
    outline: none;
  }
  button {
    border: 0;
    width: 150px;
  }
  button:hover {
    cursor: pointer;
  }
  .redtext {
    font-weight: bold;
  }
  .search-results {
    margin-top: 50px;
  }
  .search-result {
    display: flex;
    max-width: 600px;
    margin: 20px auto;
    border: 1px solid #999999;
    max-height: 150px;
  }
  .search-result img {
    max-width: 150px;
  }
  .search-result .result-term {
    margin-left: 20px;
    text-align: left;
    color: #333;
  }
</style>
